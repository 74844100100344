<template>
    <v-card class="filter-card">
        <v-card-text>
            <v-row class="d-flex align-items-center">
                <div class="col-xs-12 col-sm-12 col-md-4">
                    <v-text-field v-model="name" label="Company Name" width="256"  dense clearable hide-details prepend-inner-icon="mdi-magnify" @keydown.enter="onFilterConfirm" />
                </div>
                <div class="col-sm-12 col-md-2">
                    <v-autocomplete
                        v-model="zipcode"
                        :items="zipcodes"
                        label="Zip Code"
                        cache-items
                        dense
                        clearable
                        hide-no-data
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                    />
                </div>
                <div class="col-sm-12 col-md-4">
                    <v-autocomplete
                        v-model="naicsCodeDescription"
                        :items="naicsCodeDescriptions"
                        label="Industry"
                        cache-items
                        dense
                        clearable
                        hide-no-data
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                    />
                </div>
                <div class="col-sm-12 col-md-auto">
                    <v-btn class="search" color="red" dark @click="onFilterConfirm">Search</v-btn>
                </div>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { pick, omitBy } from 'lodash'
import { api } from '../util/api'
import zipcodeMap from '@/shared/zipcodes'

export default {
    data () {
        return {
            name: '',
            zipcode: '',
            naicsCodeDescription: '',
            naicsCodeDescriptions: [],
            type: 'wages',
        }
    },
    computed: {
        path () {
            return this.$route.path.split('/')[1]
        },
        zipcodes () {
            return Object.keys(zipcodeMap)
        },
    },
    created () {
        this.queryParamKeys = ['name', 'zipcode', 'naicsCodeDescription']
        this.type = 'wages'

        const queryParams = pick(this.$route.query, this.queryParamKeys)
        Object.assign(this.$data, queryParams)

        api.get('nacisDescriptions')
            .then(res => this.naicsCodeDescriptions = res)
    },
    methods: {
        onFilterConfirm () {
            if (!this.type) {
                alert('Please select if you wish to search for wage or permit violations')
                return
            }
            const queryParamKeys = {
                wages: ['name', 'zipcode', 'naicsCodeDescription'],
                permits: ['name', 'zipcode'],
            }[this.type]
            const query = omitBy(pick(this.$data, queryParamKeys), v => !v)
            this.$router.push({ path: `/${this.type}/search`, query }).catch(console.warn)
        },
    },
}
</script>
<style>
.v-messages{
  min-height: 0px !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
  /* border-top:1px solid #000; */
}
.v-input__slot {
  margin-bottom:0px !important;
}
.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: white !important;
  border-style: unset !important;
  border-width: unset !important;
}
.v-input .v-label {
  font-family: 'Poppins', sans-serif;
  color:#000 !important;
  font-size:.9rem !important;
  height: 20px;
  line-height: 20px;
  letter-spacing: 0px !important;
  font-weight:500;
}
.col-md-auto{
  flex: 1 0 auto !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  width: 12rem;
  font-weight: 500;
  border-radius: 0px;
  background-color: #D91F2A;
  color: #fff;
  border: 0px;
  padding: 20px 31px 20px 31px;
  height:50px;
  width:100%;
}
.v-btn{
  width: 12rem;
  font-weight: 500;
  border-radius: 0px;
  background-color: #D91F2A;
  color: #fff;
  border: 0px;
  padding: 20px 31px 20px 31px;
}
.v-btn__content{
  font-family: 'Poppins', sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0px !important;
}
.search{
  margin: 0px 0px 0px auto !important;
}
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.v-application .primary--text{
  color:#000!important;
  caret-color:#000!important;
}
.v-text-field--outlined{
  transition:none;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: transparent !important;
}
.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 16px 36px;
}
@media (max-width: 576px) {
  .v-card__subtitle, .v-card__text, .v-card__title {
    padding: 24px 16px;
  }
}
</style>
<style lang="scss" scoped>
.filter-card {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    border-radius:0px;
    padding: 0px !important
}
.radios {
    margin: 12px 12px 0;
}
// .search {
//     margin: 12px 12px 12px auto;
// }
</style>
